import React from "react"
import { Affix, Layout, Row, Col } from "antd"
import FA from "react-fontawesome"
import FeatherIcon from "feather-icons-react"
// eslint-disable-next-line import/no-extraneous-dependencies
import { globalHistory } from "@reach/router"
import style from "./sidebar.module.less"
import { useWindowSize } from "../../../utils/hooks"
import Config from "../../../../config"

const { Content } = Layout
const { linkedin, github, dribble, googleplay } = Config.social

const DomContent = () => (
  <aside>
    <div className={style.profileAvatar} />
    <div className={`${style.name} centerAlign`}>
      <div className={`${style.boxName} centerAlign`}>
        <h2>
          Sukhdip <span>Sandhu</span>
        </h2>
      </div>
      <div className={`${style.badge} ${style.badgeGray}`}>
        Android Analyst & Developer
      </div>
      <div className="centerAlign box">
        <a
          href={github}
          target="_blank"
          label="button"
          rel="noopener noreferrer"
        >
          <i class="fa fa-github" aria-hidden="true"></i>
        </a>
        <a
          href={linkedin}
          target="_blank"
          label="button"
          rel="noopener noreferrer"
        >
          <i class="fa fa-linkedin" aria-hidden="true"></i>
        </a>
        <a
          href={dribble}
          target="_blank"
          label="button"
          rel="noopener noreferrer"
        >
          <i class="fa fa-dribbble" aria-hidden="true"></i>
        </a>
        <a
          href={googleplay}
          target="_blank"
          label="button"
          rel="noopener noreferrer"
        >
          <i class="fa fa-play" aria-hidden="true"></i>
        </a>
      </div>
      <div className={style.resumeDownload}>
        <a href="mailto:sukhdip_sandhu@hotmail.com" target="_top">
          Contact me!
        </a>
      </div>
    </div>
  </aside>
)

const Sidebar = props => {
  const [width] = useWindowSize()
  const { children } = props
  const { pathname } = globalHistory.location
  let domContent = <DomContent />
  if (width > 997) {
    domContent = (
      <Affix offsetTop={0}>
        <DomContent />
      </Affix>
    )
  }
  if (width < 768) {
    domContent = <></>
    if (pathname === "/") {
      domContent = <DomContent />
    }
  }
  return (
    <>
      <Layout>
        <Content className={`${style.content} ${style.background}`}>
          <Row>
            <Col sm={24} md={9} lg={6} className={style.sidebarContent}>
              {domContent}
            </Col>
            <Col sm={24} md={15} lg={18}>
              <Layout
                className={`${style.background} ${style.boxContent} borderRadiusSection`}
              >
                {children}
              </Layout>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  )
}

export const Sidebar404 = props => {
  const { children } = props
  return (
    <Layout>
      <Content className={`${style.content} ${style.background} `}>
        <Row>
          <Col sm={24} md={24} lg={24}>
            <Layout
              className={`${style.background} ${style.boxContent} ${style.sideBar404Radius}`}
            >
              {children}
            </Layout>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default Sidebar
